.banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner-main {
    display: flex;
    flex-direction: column;
}

.banner-img {
    width: 100%;
    height: auto;
}

.week-product-img {
    width: 100%;
    height: auto;
}

.wood-panel-img {
    display: none;
}

@media (min-width: $breaking-point) {
    .banner {
        margin: 0 -1.5rem;

        &-main {
            flex-direction: row;
            justify-content: space-between;
            overflow: hidden;
        }

        &-footer {
            margin: 0 -1.75vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        &-image {
            flex-basis: 50%;
        }
    }

    .week-product-img {
        flex-basis: 50%;
    }

    .wood-panel-img {
        display: inline;
        justify-content: center;
        box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }
}
