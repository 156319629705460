// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito';
$font-family-oswald: Oswald;
$font-family-roboto: Roboto;
$font-family-montserrat: Montserrat;
$font-family-fontawesome: "Font Awesome 5 Free";
$font-size-base: 1.6rem;
$font-size-small: 1.2rem;
$font-size-big: 2.6rem;
$font-size-huge: 4rem;
$line-height-base: 1.6;
$border-radius: .5rem;
$breaking-point: 64em;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #ff165c;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$cdpink: #ce2790;
$lightgray: #EEEEEE;
$gray: #999999;
$comment-pink: #ffccff;
$light-black:#333333;
$visne: #8b2c68;
$breadcrumb-pink: #C69;
$gold: #ffcc00;
