.product-row {
    flex-direction: column;

    .col {
        margin: 0;
        padding: 0;
    }
}

.product-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 0.05vw solid #EEEEEE;
}

.product-ribbon {
    width: 15rem;
    height: 15rem;
    overflow: hidden;
    position: absolute;

    span {
        position: absolute;
        display: block;
        width: 22.5rem;
        height: 3.5rem;
        padding: 0.5rem 0;
        background-color: yellow;
        box-shadow: 0 0.5rem 0.5rem rgba(0,0,0,.1);
        color: black;
        font-weight: bold;
        font-family: $font-family-oswald;
        text-shadow: 0 0.1rem 0.1rem rgba(0,0,0,.2);
        text-transform: uppercase;
        text-align: center;
        right: -1.5rem;
        top: 3.5rem;
        transform: rotate(-45deg);
    }


}

.product-ribbon::before,

.product-ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 0.5rem solid #c04b41;
    border-top-color: transparent;
    border-left-color: transparent;
}

.product-ribbon::before {
    top: 0;
    right: 0;
}

.product-ribbon::after {
    bottom: 0;
    left: 0;
}

.product-img-container {
    padding: 1.9vmin;
}
.product-name-container {
    display: flex;
    justify-content: center;
    font-family: $font-family-roboto;
    a {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
    }
}

.product-price-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.price {
    font-size: $font-size-big;
    font-family: $font-family-oswald;
}

.damped-price {
    padding: 0 0.5vw;
    color: grey;
    font-size: $font-size-big;
    text-decoration-line: line-through;
}

@media (min-width: $breaking-point) {
    .product-row {
        flex-direction: row;

        .col {
            max-width: 33.33%;
        }
    }
}
